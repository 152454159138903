.contactHeading {
  display: flex;
  justify-content: center;
  font-size: 100px;
  color: rgb(104, 7, 7);
}
.formDiv {
  display: flex;
  justify-content: space-around;
}

.formSectionHeading {
  font-size: 50px;
  color: rgb(104, 7, 7);
}

.formSectionSubHeading {
  font-size: 20px;
  color: rgb(104, 7, 7);
}

.nameItem,
.numberItem {
  font-size: 20px;
}
.partnerDiv {
  height: 500px;
}
.partnerNameInput {
  display: flex;
  justify-content: space-between;
}
.partnerDiv {
  background-color: rgb(244, 219, 208);
}
.partnerHeading {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 40px;
  background-color: rgb(104, 7, 7);
}
.contactOutlined,
.phoneOutlined,
.emailOutlined {
  font-size: 50px;
  margin-right: 20px;
  color: rgb(104, 7, 7);
}
.contactDiv1 {
  margin-top: 50px;
  display: flex;
}
.address,
.phoneOutlinedHeading,
.email {
  color: rgb(51, 12, 12);
}
.ownerName {
  font-size: 25px;
}
.place,
.phoneOutlinedHphNo,
.gmail {
  font-size: 20px;
}
.contactMainDiv {
  display: flex;
  justify-content: space-around;
}
.phoneNumberSection {
  display: flex;
  margin-top: 50px;
}
.emailSection {
  display: flex;
}
.brochureInput {
  width: 600px;
  height: 50px;
  margin-left: 350px;
}
.brochureButton {
  background-color: rgba(19, 17, 17, 0.947);
  margin-left: 350px;
  margin-top: 20px;
  height: 50px;
}
Input {
  width: 300px;
}
.whatsAppDiv {
  display: flex;
}
