.stepsWeFollowHeading {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.stepsWeFollow {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.stepsWeFollowDescriptionDiv,
.stepsWeFollowLandPrepartion {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 650px;
}
.stepsWeFollowDescriptionDivPlanting,
.stepsWeFollowDescriptionDivMonitoring,
.stepsWeFollowDescriptionDiva,
.stepsWeFollowDescriptionDivFertilization {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 550px;
}
@media (max-width: 600px) {
  .stepsWeFollowDescriptionDiv {
    height: 540px;
    width: 400px;
  }
  .body {
    margin: 5px;
  }
  .stepsWeFollowDescriptionAged {
    height: 450px;
    width: 400px;
    margin-left: 50px;
  }
  .stepsWeFollowDescriptionpest {
    height: 400px;
    width: 400px;
    margin-left: 50px;
  }
  .stepsWeFollowDescriptionHar {
    height: 450px;
    width: 400px;
    margin-left: 50px;
  }
  .stepsWeFollowDescriptionWeed {
    height: 450px;
    width: 400px;
    margin-left: 50px;
  }
  .stepsWeFollowDescriptionIrrigation {
    height: 420px;
    width: 380px;
    margin-left: 40px;
  }
  .stepsWeFollowDescriptionDivPlanting {
    height: 450px;
    width: 380px;
  }
  .stepsWeFollowDescriptionDivMonitoring,
  .stepsWeFollowDescriptionDivFertilization {
    height: 500px;
    width: 380px;
    margin-left: 20px;
  }
  .stepsWeFollowLandPrepartion {
    height: 560px;
    width: 380px;
  }
}
.stepsWeFollowDescriptionDiva {
  height: 800px;
}
.stepsWeFollowContentDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stepWeFollowCopyRightDiv {
  height: 300px;
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.stepsWeFollowContentSectionPara2 {
  width: 400px;
  margin-top: 20px;
}
