.slider-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Ensure the container takes up full height of the slider */
  position: relative; /* For positioning the text */
}

.slider-text {
  position: absolute;
  display: flex;
  justify-content: end;
  top: 50%; /* Position text vertically in the middle */
  left: 50%; /* Position text horizontally in the middle */
  transform: translate(-50%, -50%); /* Center the text */
  font-size: 80px;
}

.slider-image {
  height: 800px;
  object-fit: cover;
  width: 100%;
}
