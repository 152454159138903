.LowGiRice {
  background-color: #f5f5f5;
  margin-top: 0px;
}

.lowGiRiceDiv {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}
.lowGiRiceHeading {
  display: flex;
  justify-content: center;
  font-size: 50px;
  color: #c9b377;
}
/* .img:hover{
   height: 350px;
   transform: scale(0.2s);
} */
.contentSection {
  width: 280px;
  height: 130px;
}
.contentSectionPara1 {
  font-size: 18px;
  font-weight: bold;
  color: #c9b377;
}
.copyRightDiv {
  height: 300px;
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.LowGiRice {
  margin-left: 40px;
}
