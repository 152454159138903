.components {
  display: flex;
  width: 850px;
  justify-content: space-evenly;
  margin-right: 40px;
  align-items: center;
  color: rgb(247, 247, 247);
  list-style: NONE;
  font-size: 14px;
}
.logo {
  color: rgb(247, 247, 247);
  font-size: 18px;
}
.AboutSection1 {
  box-shadow: auto !important;
  height: 1400px;
  margin: 20px;
}

.aboutimg {
  width: 1300px !important;
  height: 300px;
  margin: 0px;
  padding: 0px;
}
.AboutSection {
  height: 500px !important;
  width: 640px !important;
}
.PlantingImage {
  width: 300px !important;
  height: 420px !important;
  margin: 0px !important;
  box-shadow: none !important;
  padding: 0px;
}

.CTRLGSectionHeading {
  font-size: 20px;
  color: #c9b377;
  margin: "0px";
  padding: 0%;
  font-weight: 800;
}
.Link {
  color: rgb(247, 247, 247);
  text-decoration: NONE;
  list-style: NONE;
  font-size: 16px;
}
.headingText {
  display: flex;
  justify-content: "flex-start";
}

body {
  background-color: rgb(247, 247, 247);
}
@media (max-width: 600px) {
  .whyOrganicHeading {
    font-size: 30px;
  }
}

@media (max-width: 600px) {
  .mobileContainer {
    align-items: center;
    justify-content: center;
    box-shadow: none !important;
  }
  .mobilePic {
    width: 400px;
  }

  .slider-image {
    width: 400px !important;
    height: 280px !important;
  }
  .LowGiSection {
    width: 400px;
    height: 300px;
    box-shadow: none !important;
  }
  .brand {
    width: 300px;
    height: 300px;
    box-shadow: none !important;
  }
  .containerpic {
    width: 300px;
    box-shadow: none !important;
    height: 300px !important;
    margin: 0px;
  }
  .PlantingImage {
    width: 300px;
    height: 420px !important;
    margin: 0px !important;
    box-shadow: none !important;
    padding: 0px;
  }
  .RiceBagImage {
    width: 300px !important;
    height: 400px !important;
    margin: 0px !important;
    box-shadow: none !important;
  }
  .CTRLGSection {
    width: 300px;
    height: 1300px !important;
    box-shadow: none !important;
  }
  .CTRLGSectionHeading {
    font-size: 15px;
    color: #c9b377;
    margin: "0px";
    padding: 0%;
    font-weight: 1000;
  }
  .LandPreparation {
    height: 1000px !important;
    width: 350px !important;
    margin-bottom: 50px;
  }
  .stepsWeFollowContentSection1 {
    height: 500px !important;
  }
  .LowGiSection {
    width: 300px;
    height: 450px !important;
    box-shadow: none !important;
    padding: 0px;
    margin: 40px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .riceImage {
    width: 300px;
    height: 400px !important;
    box-shadow: none !important;
  }
  .PlantingPic {
    max-width: 300px !important;
    height: 500px !important;
    margin: 0px !important;
    padding: 0px;
    padding-right: 50px;
    box-shadow: none !important;
  }
  .whyOrganicHeading {
    font-size: 20px !important;
  }
  .libraryHeading {
    font-size: 20px !important;
  }
  .heading1 {
    font-size: 20px !important;
  }
  .AboutSection {
    height: 950px !important;
    width: 300px !important;
    box-shadow: none !important;
  }
  .AboutSection1 {
    height: 110px !important;
    width: 350px !important;

    box-shadow: none !important;
  }
  /* .para1{
    height: 600px !important;
  } */
  .table {
    width: 200px;
  }
  .section1 {
    height: 580px !important;
  }
  .controlSection {
    width: 400px !important;
    height: 350px !important;
  }

  .benefits {
    font-size: 40px !important;
  }
  .container img {
    width: 100%;
    max-width: px !important;
    height: auto;
  }
  .container1 {
    width: "400px" !important;
    height: 800px !important;
  }
  .mobile {
    max-width: 400px !important;
  }
  .pic {
    max-width: 400px !important;
  }

  .container img {
    width: 100%; /* Use full width on smaller screens */
    max-width: 400px !important; /* Limit to 400px width */
    height: auto; /* Maintain aspect ratio */
  }
  .aboutimg {
    width: 300px !important;
    height: 300px !important;
    margin: 0px !important;
  }
  .brandImage {
    width: 300px !important;
  }

  .logoMain {
    width: 400px !important;
  }
  .table {
    width: 300px !important;
  }
  .Table {
    width: 300px !important;
    height: 700px !important;
  }
  .para {
    font-size: 15px !important;
    width: 300px !important;
    height: 300px !important;
  }
  .para {
    font-size: 15px !important;
    width: 300px !important;
    height: 200px !important;
  }
  .img {
    width: 300px !important;
    height: 400px !important;
  }
  .Photos {
    width: 300px !important;
    height: 400px !important;
  }
  .OrganicSection1 {
    width: 300px !important;
    height: 400px !important;
  }
  .OrganicSection1Img {
    width: 300px !important;
    height: 400px !important;
  }
  .OrganicDescription {
    width: 400px !important;
    height: 820px !important;
  }
  .family {
    width: 300px !important;
    height: 300px !important;
    margin: 0px;
    padding: 0px;
  }
  .map {
    width: 350px;
    height: 500px;
    margin: 0px;
    padding: 0px;
  }
  .formSection {
    width: 300px;
  }
  .contactHeading {
    font-size: 40px;
  }
  .LargerScreen {
    display: none;
  }
  .about {
    width: 300px !important;

    boxshadow: "none" !important;
  }

  .header {
    background-color: white;
    height: "100px";
  }
  .slider-item {
    width: 400px !important;
  }
  .img1 {
    width: 300px !important;
  }

  .mobilePic {
    width: 350 !important;
    height: 400 !important;
    margin-top: 0px !important;
    padding: 0px !important;
  }
  .containerLowgi {
    width: 350 !important;
    height: 700 !important;
    font-size: 5px !important;
  }
  .section1 {
    width: 350px;
    height: 400px;
  }
  .plantImage {
    width: 300;
    height: 400px;
    margin: 0px;
    padding: 0px;
  }
  .FatherImage {
    width: 300px !important;
    height: 400px !important;
    margin: 0px !important;
    padding: 0px;
  }

  .section {
    width: 300px;
    height: 600px !important;
  }
  .rice {
    width: 300px;
  }
  .stepsWeFollowHeading {
    font-size: 20px !important;
    display: flex;
    justify-content: center;
    margin-left: 20px;
  }
}
.aboutimg {
  width: 300px !important;
  height: 300px;
  box-shadow: none !important;
}

@media (min-width: 800px) {
  .mobile {
    display: none;
  }
  .header {
    background-color: black;
    height: 100px;
    display: flex;
    align-items: center;
  }
}
.advantagesDiv {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-evenly;
}
