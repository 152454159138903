@media (max-width: 600px) {
  .certificateHeading {
    font-size: 30px !important;
  }
  .FssaiHeading {
    font-size: 20px !important;
    margin-left: 20px !important;
  }
  .images {
    width: 370px !important;
  }
}
